<template>
  <main>
    <trac-back-button :backAction="backAction">
      KYC Menu
    </trac-back-button>
    <trac-loading v-show="loading" />
    <div class="mt-2 text-sm font-medium">
      Complete KYC by verifying the following.
    </div>
    <form @submit.prevent="verifyEmail"
      class="flex justify-center flex-col bg-white rounded-md mt-12 lg:mr-0 mb-10 mr-4 big-shadow p-5 items-center">
      <h1 class="mt-10 text-primaryBlue font-medium">Verify Email</h1>
      <svg class="w-16 h-16 mt-5 mb-5" viewBox="0 0 78 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="74.1071" height="7.41071" fill="#B2E7FF" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.3953 2H68.5577C72.6251 2 75.953 5.32788 75.953 9.3953V53.7671C75.953 57.8345 72.6251 61.1624 68.5577 61.1624H9.3953C5.32788 61.1624 2 57.8345 2 53.7671V9.3953C2 5.32788 5.32788 2 9.3953 2Z"
          stroke="#253B95" stroke-width="3.69765" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M76.1071 9.39551L39.0536 35.2791L2 9.39551" fill="#B2E7FF" />
        <path d="M76.1071 9.39551L39.0536 35.2791L2 9.39551" stroke="#253B95" stroke-width="3.69765"
          stroke-linecap="round" stroke-linejoin="round" />
      </svg>

      <div v-if="!confirm">
        <p class="text-center tracking-normal text-sm mt-3">
          Please confirm your email and an OTP will be sent to you.
        </p>

        <div class="mb-10 mt-16 max-w-md w-full">
          <trac-input placeholder="Email Address" type="email" v-model="payload.reference" required disabled />
          <!-- <div class="inline-flex mt-5">
          <svg
            class="w-4 h-4 mr-2"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99967 0.333496C4.93692 0.333496 0.833008 4.43741 0.833008 9.50016C0.833008 14.5629 4.93692 18.6668 9.99967 18.6668C15.0624 18.6668 19.1663 14.5629 19.1663 9.50016C19.1663 4.43741 15.0624 0.333496 9.99967 0.333496ZM10.9163 14.0835H9.08301V8.5835H10.9163V14.0835ZM10.9163 6.75016H9.08301V4.91683H10.9163V6.75016Z"
              fill="#979797"
            />
          </svg>
          <p class="text-xs text-primaryGray">
            To quickly retreive your BVN, kindly dial *565*0# on your <br />
            mobile device.
          </p>
        </div> -->
        </div>

        <div class="mt-8 w-full mb-6">
          <trac-button class="w-full" type="submit"><span class="px-12 text-center">Send OTP</span></trac-button>
        </div>
      </div>
      <div v-else>
        <p class="text-center tracking-normal text-sm mt-3">
          An OTP has been sent to you, please check your email.
        </p>
        <p class="text-center tracking-normal text-xs mt-3">
          Didn’t recieve an email? <a href=""> Click resend.</a>
        </p>
      </div>
    </form>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      confirm: false,
      loading: false,
      isCustomKycRoute: false,
      payload: {
        reference: "",
        verificationType: "EMAIL",
        businessId: "",
      },
    };
  },

  methods: {
    backAction() {
      this.$router.replace({ name: 'KycProfile', params: { custom: this.isCustomKycRoute } });
    },
    async verifyEmail() {
      this.loading = true;
      let res = await this.$store.dispatch("SEND_OTP", this.payload);
      if (res.status) {
        eventBus.$emit("trac-alert", {
          message: "OTP sent succesfully, kindly check your email address",
        });
        this.$router.push({ name: "VerifyOtp", query: { custom: this.isCustomKycRoute } });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.payload.businessId = GET_USER_BUSINESS_ID();
    this.payload.reference = GET_USER_BUSINESS_DATA().email;
    this.isCustomKycRoute = this.$route.query.custom;
  },
};
</script>

<style>
.g-gray {
  background: rgba(230, 227, 227, 0.55);
}
</style>
